import type { RouteRecordRaw } from 'vue-router'
import { guardEnterProject, guardPlanningResource, guardProjectAccess, guardProjectCreation } from '@/config/router/navigationsGuards/guardProject'

const MODULE_NAME = 'PROJECT'
const MODULE_ID = 5

const projectRoutes: Array<RouteRecordRaw> = [
  {
    path: '/project',
    meta: {
      requiresAuth: true,
    },
    beforeEnter: [guardProjectAccess],
    children: [
      {
        path: '',
        name: 'Projects',
        component: () => import('@/views/project/ProjectList.vue'),
        meta: {
          requiresAuth: true,
          titleTranslationKey: 'pageTitle.projects',
        },
        beforeEnter: [],
      },
      {
        path: 'advancement',
        name: 'ProjectsAdvancement',
        component: () => import('@/views/project/ProjectListAdvancement.vue'),
        meta: {
          requiresAuth: true,
          titleTranslationKey: 'pageTitle.projectsAdvancement',
        },
        beforeEnter: [],
      },
      {
        path: 'planning/resources',
        name: 'ProjectPlanningResources',
        component: () => import('@/views/project/planning/PlanningResources.vue'),
        meta: {
          requiresAuth: true,
          titleTranslationKey: 'pageTitle.planningResources',
        },
        beforeEnter: [],
      },
      {
        path: 'planning/projects',
        name: 'ProjectPlanningProjects',
        component: () => import('@/views/project/planning/PlanningProjects.vue'),
        meta: {
          requiresAuth: true,
          titleTranslationKey: 'pageTitle.planningProjects',
        },
        beforeEnter: [],
      },
      {
        path: 'create',
        name: 'ProjectCreation',
        component: () =>
          import('@/views/project/ProjectCreateEdit.vue'),
        meta: {
          requiresAuth: true,
          titleTranslationKey: 'pageTitle.projectCreation',
        },
        beforeEnter: [],
      },
      {
        path: ':projectId',
        meta: {
          requiresAuth: true,
        },
        beforeEnter: [],
        children: [
          {
            path: '',
            name: 'ProjectInformationPage',
            component: () =>
              import('@/views/project/projectDetails/Informations.vue'),
            meta: {
              requiresAuth: true,
            },
            beforeEnter: [],
          },
          {
            path: 'staffing',
            name: 'ProjectStaffing',
            component: () =>
              import('@/views/project/projectDetails/ProjectStaffing.vue'),
            meta: {
              requiresAuth: true,
            },
            beforeEnter: [],
          },
          {
            path: 'budget',
            name: 'ProjectBudget',
            component: () =>
              import('@/views/project/projectDetails/ProjectBudget.vue'),
            meta: {
              requiresAuth: true,
            },
            beforeEnter: [],
          },
          {
            path: 'edit',
            name: 'ProjectEdit',
            component: () =>
              import('@/views/project/ProjectCreateEdit.vue'),
            meta: {
              requiresAuth: true,
            },
            beforeEnter: [],
          },
          {
            path: 'monitoring',
            name: 'ProjectMonitoring',
            component: () =>
              import('@/views/project/projectDetails/monitoring/Cumulated.vue'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'timesheet',
            name: 'ProjectTimesheet',
            component: () =>
              import('@/views/project/projectDetails/monitoring/TimeSheetDetails.vue'),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },

    ],

  },
]

export default projectRoutes.map(route => ({ ...route, meta: { ...route.meta, moduleName: MODULE_NAME, moduleId: MODULE_ID } }))
