e<script lang="ts" setup>
import { computed, toRefs, useAttrs } from 'vue'
import { RouterLink } from 'vue-router'
import IconLoading from '~icons/mdi/loading'
import type { TColors, TSize, TLoadingStatus } from '@/services/application/interfaces'
import { bgColors, size as sizeConst } from '@/services/application/constantes'
import type { StrictTailwindRoundedClass } from '@/services/application'
import SvgSpinners3DotsBounce from '~icons/svg-spinners/3-dots-bounce'
import LineMdCheckAll from '~icons/line-md/check-all'
import LineMdCloseSmall from '~icons/line-md/close-small'

type bButton = {
  bg?: keyof typeof bgColors
  size?: keyof typeof sizeConst
  haveError?: boolean
  isLoading?: boolean
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
  loadingStatus?: TLoadingStatus
  to?: string | object
  href?: string
  download?: {
    url: string
    name: string
  }
  dot?: {
    content?: string
    bg?: TColors
  } | boolean
  icon?: boolean
  rounded?: StrictTailwindRoundedClass
  required?: boolean
}

const props = withDefaults(defineProps<bButton>(), {
  size: 'small',
  bg: 'primary',
  type: 'button',
  haveError: false,
  isLoading: false,
  disabled: false,
  icon: false,
  rounded: 'rounded-lg',
  required: false,
})
const objAttributes = computed(() => {
  const attrs = useAttrs()
  const { to, download, href } = props
  const obj = { ...attrs, to }
  // @ts-expect-error
  if (download)
    obj.href = download.url

  if (href)
    obj.href = href

  return obj
})

const componentType = computed(() => {
  const { to, download, href } = props
  if (to)
    return RouterLink
  if (download)
    return 'a'
  if (href)
    return 'a'
  return 'button'
})

const { bg, haveError, isLoading, disabled, type } = toRefs(props)

const bgColor = computed(() => {
  if (!haveError.value)
    return bgColors[bg.value]
  else if (haveError.value)
    return bgColors.error
  else if (isLoading.value)
    return `${bgColors.loading} ${bg.value}`
})
</script>

<template>
  <component
    v-bind="objAttributes"
    :is="componentType"
    class="relative flex justify-center items-center gap-2 transition-all" :class="[bgColor, sizeConst[size], disabled ? 'opacity-40 cursor-not-allowed' : '', { 'cursor-pointer': to }, icon ? '!p-2 rounded-full' : rounded]" :disabled="isLoading || disabled || !!loadingStatus" :type="type" :target="download?.name ? '_blank': undefined" :download="download?.name"
  >
    <div v-if="loadingStatus === 'error'" class="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-danger-400" :class="rounded">
      <LineMdCloseSmall />
    </div>
    <div v-if="loadingStatus === 'success'" class="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-success-400" :class="rounded">
      <LineMdCheckAll />
    </div>
    <div v-if="isLoading || loadingStatus === 'loading'" class="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-gray-400/60" :class="rounded">
      <SvgSpinners3DotsBounce />
    </div>
    <slot />
    <span
      v-if="dot" :class="[dot.bg ? bgColors[dot.bg] : bgColor, { 'border-base': dot.bg !== 'base' && bg !== 'base' }]"
      class="absolute top-0 right-0 transform-gpu translate-x-2/3 -translate-y-2/3 square rounded-full text-[0.7rem] w-5 flex justify-center items-center p-[2px] overflow-hidden"
    >
      <slot name="dot">
        {{ dot.content }}
      </slot>
    </span>
  </component>
</template>

<style>
</style>
